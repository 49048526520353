<template>
  <base-section id="theme-features">
    <base-section-heading :title="$t('sections.themefeatures.title')">
      {{ $t('sections.themefeatures.subtitle') }}
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
    }),
    computed: {
      features () {
        return [
          {
            color: 'primary',
            dark: true,
            title: this.$t('sections.themefeatures.features.1.title'),
            icon: 'mdi-puzzle',
            text: this.$t('sections.themefeatures.features.1.text'),
          },
          {
            title: this.$t('sections.themefeatures.features.2.title'),
            icon: 'mdi-share-variant-outline',
            text: this.$t('sections.themefeatures.features.2.text'),
            color: 'primary',
            dark: true,
          },
          {
            color: 'primary',
            dark: true,
            title: this.$t('sections.themefeatures.features.3.title'),
            icon: 'mdi-home-group',
            text: this.$t('sections.themefeatures.features.3.text'),
          },
          {
            title: this.$t('sections.themefeatures.features.4.title'),
            icon: 'mdi-monitor-dashboard',
            color: 'primary',
            dark: true,
            text: this.$t('sections.themefeatures.features.4.text'),
          },
          {
            title: this.$t('sections.themefeatures.features.5.title'),
            icon: 'mdi-cash-multiple',
            text: this.$t('sections.themefeatures.features.5.text'),
            color: 'primary',
            dark: true,
          },
          {
            title: this.$t('sections.themefeatures.features.6.title'),
            icon: 'mdi-truck-delivery-outline',
            text: this.$t('sections.themefeatures.features.6.text'),
            color: 'primary',
            dark: true,
          },
        ]
      },
    },
  }
</script>
